<template>
  <v-autocomplete
    outlined
    rounded
    dense
    v-bind="this.$attrs"
    item-text="name"
    :items="tokens"
    append-icon="none"
    prepend-inner-icon="mdi-magnify"
    :auto-select-first="true"
    :loading="loading"
    v-model="selectedToken"
    @update:search-input="searchText = $event"
    @focus="onFocus"
    @blur="unFocus"
    :hide-no-data="true"
  >
  </v-autocomplete>
</template>

<script>
import { web3 } from '@/helpers/LockHandler.js'
import { mapState } from 'vuex'
export default {
  props: {
    defaultTokens: Object,
  },
  data: () => ({
    tokens: [],
    initData: [],
    cache: [],
    selectedToken: '',
    focusState: false,
    searchText: '',
  }),
  computed: {
    ...mapState('auth', ['multiSendHandler', 'bnbInfo']),
  },
  created() {
    try {
      this.loading = true
      const defaultData = this.defaultTokens.tokenAddress ? this.defaultTokens : this.bnbInfo
      this.initData = [this.bnbInfo]
      this.tokens = [defaultData]
      this.selectedToken = this.tokens[0].name
      this.$emit('updated', this.tokens[0])
      this.cache = this.tokens
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    onFocus() {
      this.selectedToken = null
      this.tokens = this.initData
      this.$emit('focused')
    },
    async unFocus() {
      try {
        this.loading = true
        if (!web3.utils.isAddress(this.searchText)) {
          if (this.selectedToken !== this.tokens[0].name) {
            this.tokens = this.cache
            this.selectedToken = this.tokens[0].name
          }
        } else {
          const res = await this.multiSendHandler.getTokenInfoByAddress(this.searchText)
          this.tokens = [res]
          this.selectedToken = this.tokens[0].name
        }
        this.$emit('updated', this.tokens[0])
        this.cache = this.tokens
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
